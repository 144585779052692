



































import { BannerStatus, LoadingStatusType } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { LatestUpdateTableViewModel, SortOrderLatestUpdateRequest } from "@/model/latestUpdate/latestUpdateModel";
import { Component, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import { namespace } from "vuex-class";

const StoreLatestUpdate = namespace("LatestUpdate");
@Component({
  components: {
    draggable,
  },
})
export default class SortOrderLatestUpdate extends Vue {
  private dragging = false;

  @StoreLatestUpdate.Action
  private DoSortBanner!: (sortOrderLatestUpdateRequest: SortOrderLatestUpdateRequest[]) => void;
  @StoreLatestUpdate.Action
  private DoGetBannerTable!: (latestUpdateTableViewModel: LatestUpdateTableViewModel) => void;
  @StoreLatestUpdate.Getter
  private getBannerTableLoadingStatus!: LoadingStatusType;
  @StoreLatestUpdate.Getter
  private getsortOrderBannerRequestLoadingStatus!: LoadingStatusType;
  @StoreLatestUpdate.Getter
  private getBannerTableListModels!: Array<any> | null;
  private table: any = {
    detail: [],
  };
  private bannerStatusText = ["None", "Pending", "Live", "Expired"];
  created() {
    this.GetBannerTable();
  }
  @Watch("getBannerTableLoadingStatus", { immediate: true })
  getBannerTableLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      this.table.detail = [];
      if (this.getBannerTableListModels && this.getBannerTableListModels.length > 0) {
        this.getBannerTableListModels.forEach((item: any) => {
          this.table.detail.push(NewObject(item));
        });
        this.table.detail.sort((a: any, b: any) => a.order - b.order);
      }
    }
  }
  @Watch("getsortOrderBannerRequestLoadingStatus", { immediate: true })
  getsortOrderBannerRequestLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      alert("บันทึกสำเร็จ ข้อมูลที่จัดเรียงจะถูกอัปเดตเวลา 24:00 น.");
      this.Cancel();
    }
  }
  public GetBannerTable(pageNo = 1, pageSize = 9999) {
    const latestUpdateTableViewModel: LatestUpdateTableViewModel = {
      pageSize: pageSize,
      pageNo: pageNo,
      fromDate: "",
      toDate: "",
      name: "",
      code: "",
      bannerTabStatus: BannerStatus.Pending,
    };
    this.DoGetBannerTable(latestUpdateTableViewModel);
  }
  public Save() {
    const input: SortOrderLatestUpdateRequest[] = [];

    this.table.detail.forEach((table: any) => {
      input.push({ code: table.code, sortNumber: table.order });
    });
    this.DoSortBanner(input);
  }
  public Cancel() {
    window.location.href = "/latestUpdate";
  }
}
